import { useTheme } from 'styled-components';
import { useIconSize } from 'foundations-assets';
export const useVariantCssObject = variantTokens => {
  const theme = useTheme();

  // The type for background and border colors was broadened in
  // `ButtonStateStyles` to `Color | (string & {})` to accommodate
  // setting a transparent, non-token value for the ghost and transparent
  // button variants. In the future, this value may come from a token
  // and we can remove the variables and type casts below.
  const backgroundColorIdle = variantTokens.idle.backgroundColor;
  const borderColorIdle = variantTokens.idle.borderColor;
  const backgroundColorHover = variantTokens.hover.backgroundColor;
  const borderColorHover = variantTokens.hover.borderColor;
  const backgroundColorActive = variantTokens.active.backgroundColor;
  const borderColorActive = variantTokens.active.borderColor;
  const backgroundColorDisabled = variantTokens.disabled.backgroundColor;
  const borderColorDisabled = variantTokens.disabled.borderColor;
  return {
    display: 'inline-block',
    backgroundColor: theme.color[backgroundColorIdle],
    borderColor: theme.color[borderColorIdle],
    borderRadius: theme.borderRadius['100'],
    borderWidth: theme.borderWidth['100'],
    borderStyle: 'solid',
    color: theme.color[variantTokens.idle.color],
    ':hover:not([aria-disabled="true"])': {
      backgroundColor: theme.color[backgroundColorHover],
      borderColor: theme.color[borderColorHover],
      color: theme.color[variantTokens.hover.color]
    },
    ':active:not([aria-disabled="true"])': {
      backgroundColor: theme.color[backgroundColorActive],
      borderColor: theme.color[borderColorActive],
      color: theme.color[variantTokens.active.color]
    },
    '&[aria-disabled="true"]': {
      backgroundColor: theme.color[backgroundColorDisabled],
      borderColor: theme.color[borderColorDisabled],
      color: theme.color[variantTokens.disabled.color],
      cursor: 'auto'
    }
  };
};
export const useSizeCssObject = sizeTokens => {
  const theme = useTheme();
  const iconSize = useIconSize(sizeTokens.iconSize);
  return {
    paddingTop: theme.space[sizeTokens.paddingTop],
    paddingBottom: theme.space[sizeTokens.paddingBottom],
    paddingLeft: theme.space[sizeTokens.paddingLeft],
    paddingRight: theme.space[sizeTokens.paddingRight],
    svg: {
      width: iconSize,
      height: iconSize
    }
  };
};